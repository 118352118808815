import React, { useEffect, useState, useRef } from 'react';
import { Grid, makeStyles,  } from '@material-ui/core';
import SelectV2 from '../common/SelectV2.component';
import Box from '@material-ui/core/Box';
import Text from '../common/text.component';
import MaskedTextField from '../common/MaskedTextField.component';
import ErrorMessage from '../common/ErrorMessage.component';
import { formatFractionDigits } from '../../utils/currency.utils';

const NUMBER_MASK =  new Array(9).fill(/\d/);
const PRICE_MASK = ['$', ...(new Array(9).fill(/\d/))];
const PRICE_MASK_3 = ['$',/[1-9]/,/\d/, /\d/]
const PRICE_MASK_4 = ['$',/[1-9]/, '.', /\d/, /\d/, /\d/]
const PRICE_MASK_5 = ['$',/[1-9]/, /\d/, '.', /\d/, /\d/, /\d/]
const PRICE_MASK_6 = ['$',/[1-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/]
const PRICE_MASK_7 = ['$',/[1-9]/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/]
const PRICE_MASK_8 = ['$',/[1-9]/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/]
const PRICE_MASK_9 = ['$',/[1-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/]

const currencyFormat = (num) => {
  return '$' + Number(num).toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};

export const formaterNumber = num =>
  new Intl.NumberFormat('es-Ar').format(Number(num));

const useStyles = makeStyles(theme =>({
  inputSelect:{
    '& .MuiFormControl-root':{
      marginTop: '8px',
    },
    width: '50%',
    marginRight: '1.8rem',
    [theme.breakpoints.down('sm')]:{
      width: '100%',
      marginRight: '0rem',
      marginBottom: '2rem',
    }
  },
  container:{
    display: 'flex',
    paddingTop: '3rem',
    paddingBottom: '2rem',
    [theme.breakpoints.down('sm')]:{
      display: 'flex',
      flexWrap: 'wrap',
    }
  },
  gridContainer:{
    '& .MuiFormControl-root': {
      marginTop: '2px',
    },
    [theme.breakpoints.down('sm')]:{
      width: '100%',
    },
    display: 'grid',
    width: '50%',
  },
  textFielConteiner:{
    '& .MuiOutlinedInput-root': {
      '& fieldset':{
        borderColor: '#D1D1D1',
        height: '48px',
      },
    },
  },
  heightSelect:{
    height: '48px',
    '& > .MuiSelect-root > *': {
      fontSize: '14px',
      marginLeft: '8px',
    },
    '& > .Mui-disabled': {
      backgroundColor: '#F6F6F6',
      padding: '14px',
    }
  },
  inputLabel:{
    color: '#5c5c5c',
    marginLeft: '20px',
    fontFamily: 'Poppins',
    marginBottom: '10px',
  },
  labelDownInput:{
    color: '#717171',
    fontFamily: 'Poppins',
    marginTop: '5px',
    marginLeft: '20px',
  },
  textInput: {
    '& input': {
      paddingTop: '0.938rem',
      paddingBottom: '0.938rem',
      color: '#262626',
      fontSize: '14px',
      marginLeft: '8px',
    },
    '& label': {
      fontFamily: 'Poppins',
      color: '#717171',
      marginTop: '-5px',
      transform: 'translate(1.438rem, 1rem) scale(1)',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset':{
        borderColor: '#E8E8E8',
        height: '48px',
      },
      height: '40px',
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: '20px',
    }
  },
}));

const MIN_AMOUNT_TO_FINANCE = 10000;
const TXT_MIN_AMOUNT_ALLOWED = 'Tiene que ser mayor o igual a $10.000';

const FinancingLineChange = ({ conditionsCreditLine, creditLine, setCreditLine = ()=> {}, getDataCftQuoteAvg, amountToFinance = 0, setAmountToFinance, error, setError }) => {


  const TXT_MAX_AMOUNT_ALLOWED = `Tiene que ser menor o igual a ${currencyFormat(creditLine.condition.capMax)}`;
  const classes = useStyles();
  const [format, setFormat] = useState(PRICE_MASK_9);
  const [selectQuote, setSelectQuote] = useState(creditLine?.condition.id);
  // Ref para saber si es el primer render del componente
  const firstRenderRef = useRef(null);

  const getMask = (value) => {
    switch (value) {
      case 1:
      case 2:
      case 3:
        setFormat(PRICE_MASK_3);
        break;
      case 4:
        setFormat(PRICE_MASK_4);
        break;
      case 5:
        setFormat(PRICE_MASK_5);
        break;
      case 6:
        setFormat(PRICE_MASK_6);
        break;
      case 7:
        setFormat(PRICE_MASK_7);
        break;
      case 8:
        setFormat(PRICE_MASK_8);
        break;
      case 9:
        setFormat(PRICE_MASK_9);
        break;
      default:
        setFormat(PRICE_MASK);
        break;
    }
  };

  const handleDivBlur = event => {
    getMask(amountToFinance.toString().length);
  };
  const helperTextInput = error ? error :  `Desde ${currencyFormat(MIN_AMOUNT_TO_FINANCE)} hasta ${currencyFormat(creditLine.condition.capMax)}`;

  const handleOnFocus = event => {
    setFormat(NUMBER_MASK);
  };

  useEffect(() => {
    if(amountToFinance > creditLine.condition.capMax) {
      setError(<ErrorMessage>{TXT_MAX_AMOUNT_ALLOWED}</ErrorMessage>);
    }else
    if(amountToFinance < MIN_AMOUNT_TO_FINANCE) {
      setError(<ErrorMessage>{TXT_MIN_AMOUNT_ALLOWED}</ErrorMessage>);
    }
    else {
      setError(null);
      if(!error) {
        getDataCftQuoteAvg();
      }

    }
  }, [amountToFinance]);

  useEffect(() => {
    setFormat(NUMBER_MASK);
    // Se agrega ref para saber si es el primer render
    // para no sobreescribir el valor del monto seleccionado previamente
    if(firstRenderRef.current) {
      setAmountToFinance(() => creditLine.condition.capMax);
      getMask(creditLine.condition.capMax.toString().length);
      getDataCftQuoteAvg();
    } else {
      getMask(amountToFinance.toString().length);
      firstRenderRef.current = true;
    }
  }, [creditLine.condition]);

  const onSelectQuote = (e) => {
    setSelectQuote(e.target.value);
    const condition = conditionsCreditLine.find(condition => condition.id === e.target.value);
    setCreditLine(credit => ( { ...credit, condition } ));
  };

  const onChangeValue = (e) => {
    let newAmountValue = e.target.value;
    newAmountValue = newAmountValue.replace(/\./g, '');
    newAmountValue = newAmountValue.replace(/\$/g, '');
    setAmountToFinance(newAmountValue);
  };

  const maxQuoteText = 'Elegí la cantidad de cuotas';
  const minQuoteText = 'Cantidad de cuotas';
  const QuoteText = conditionsCreditLine?.length <= 1 ? minQuoteText : maxQuoteText

  return (
    <>{ conditionsCreditLine &&
      <Box className={classes.container}>
        <Grid className={classes.inputSelect}>
          <Text className={classes.inputLabel}>{QuoteText}</Text>
          <SelectV2
            disabled={conditionsCreditLine?.length <= 1}
            id="select-quote"
            value={selectQuote}
            isFromCatalog={true}
            onChange={onSelectQuote}
            options={conditionsCreditLine && conditionsCreditLine.map((quote) => ({
              label:`${quote.quantity} meses - TNA ${formatFractionDigits(quote.tna)}%`,
              value:quote.id,
            }))}
            className={classes.heightSelect}
          >
          </SelectV2>
        </Grid>
        <Grid className={classes.gridContainer}>
          <label className={classes.inputLabel}> Escribí el monto a financiar</label>
          <MaskedTextField
            data-test-id="input-kms"
            className={classes.textInput}
            value={amountToFinance || ''}
            mask={format}
            onChange={(evt) => onChangeValue(evt)}
            onBlur={handleDivBlur}
            onFocus={handleOnFocus}
            variant="outlined"
            error={error && error}
            helperText={helperTextInput}
          />
        </Grid>
      </Box>}
    </>
  );
};

export default FinancingLineChange;
