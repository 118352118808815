import React, { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Text from '../common/text.component';
import VehicleInfo from '../budget/VehicleInfo.component';
import { useQuery } from '@apollo/react-hooks';
import { GET_BUDGET } from '../../queries/getBudget';
import Loading from '../common/Loading.component';
import { GET_BUDGET_LEGALS } from '../../queries/getLegalsBudget';
import { useLocation } from '@reach/router';

const ACTION_DESCRIPTION ='Te pedimos disculpas por los inconvenientes.';


const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '1.7rem',
      paddingRight: '1.7rem'
    }
  },
  title: {
    marginTop: '1.25rem',
    marginBottom: '1rem',
    fontSize: '26px',
    fontWeight: '700',
    lineHeight: '1.2em',
    textAlign: "center",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  descriptiion: {
    marginTop: '1rem',
    marginBottom: '2.5rem',
    textAlign: "center",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  },
  infoContainer: {
    width: 'min(34.75rem, 100%)',
    paddingLeft: '1.125rem',
    paddingRight: '1.125rem',
    minHeight: '8rem',
    flexDirection: 'row',
    border: '1px solid',
    borderColor: theme.palette.grayScale.g300,
    borderRadius: '0.625rem',
    alignItems: 'center',

  },
  photo: {
    minHeight: '7rem',
    maxWidth: '9.375rem',
  },
  media: {
    minWidth: '9.375rem',
  },
  infoDetail: {
    marginTop: '0.75rem'
  },
  brandContainer: {
    marginBottom: '0.5rem'
  },
  yearFuelColor: {
    marginTop: '0.5rem'
  },
  forgotLink: {
    marginLeft:'0.625rem',
    marginTop: theme.spacing(1), 
  },
  legalText: {
    marginTop: '5rem',
    [theme.breakpoints.down('md')]: {
      marginTop: '3rem',
      marginRight: '1rem',
      marginLeft: '1rem'
    }
  },
  textDescription: {
    marginTop: '1rem',
    marginBottom: '0.7rem',
    paddingTop: '1rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '15.8px'
    }
  },
}));

function SuccessfulReservation() {
  const classes = useStyles();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const budgetId = searchParams.get('budgetId');
  const [carData, setCarData] = useState(null);

  const { data, refetch } = useQuery(GET_BUDGET, {
    variables: {
      id: budgetId
    },
    onCompleted: (success) => {
      success && refetch();
    }
  });

  React.useEffect(() => {
    data && setCarData(data?.getBudget || null);
  }, [data]);

  const {data: budgetLegalsResponse } = useQuery(GET_BUDGET_LEGALS, {
    variables: {
      options: {
        hasTakingCar: carData?.hasUsedCar,
        hasFinancing: carData?.hasCreditLine,
      },
    },
  });

  return carData === null ? <Loading size={20} /> :  (
    <><Box className={classes.container}>
      <Text className={classes.title}><span>{carData.client_first_name}, algo pasó y no</span>
        <span>pudimos reservar tu auto</span>
      </Text>
      <Text className={classes.descriptiion} g600>
        <span>Nos comunicaremos con vos en las próximas horas </span>
        <span>para analizar las opciones. </span>
      </Text>
      <VehicleInfo
        classes={classes}
        mobile
        car={{
          images: [carData?.image_car],
          brand: { name: carData?.catalog_marca ? carData?.catalog_marca : '' },
          model: { name: carData?.catalog_model ? carData?.catalog_model : '' },
          version: { name: carData?.catalog_version },
          is0km: true,
          year: carData?.catalog_anio || '',
          fuel: carData?.fuel,
        }}
        selectedStock={{ observation: carData?.observation, color: { name: carData?.color.name } }} />
      <Text className={classes.textDescription} g600>{ACTION_DESCRIPTION}</Text>
    </Box>
    <Box className={classes.legalText}>
        <Text g600>
          {budgetLegalsResponse.getBudgetLegals.map((text) => (
            <div key={text}>{text}</div>
          ))}</Text>
      </Box></>
    
  );
}

export default SuccessfulReservation;
