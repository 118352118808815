import React, { useEffect, useState, useCallback } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Text from '../common/text.component';
import SelectV2 from '../common/SelectV2.component';
import CardCreditLine from './CardCreditLine.component';
import Button from '../common/button.component';
import { useQuery } from '@apollo/react-hooks';
import { GET_CREDIT_LINES } from '../../queries/getCreditLines';
import { useAppContext } from '../../context/AppContext';
import { goToFinancingLineDetails, goToPresupuesto } from '../../api/navigation';
import { SET_BACK_BUTTON } from '../../context/budget/constants';
import { BUDGET } from '../../utils/constants';
import LoadingModal from '../common/LoadingModal.component';
import setCreditLine from '../../context/budget/actions/setCreditLine';
import setOrderCreditLine from '../../context/budget/actions/setOrderCreditLine';
import deleteCreditLine from '../../context/budget/actions/deleteCreditLine';

const TITLE = 'Simulá tu crédito';
const SUB_TITLE = 'Seleccioná una línea financiera.';
const LABEL_ORDEN = 'Mostrar por';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    padding: '0rem 3.1875rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0rem 1rem',
    },
  },
  root: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  header: {
    fontStyle: 'normal',
    fontSize: '1.875rem',
    lineHeight: '2.812rem',
    color: '#262626',
    [theme.breakpoints.down('sm')]: {
      marginTop: '4rem',
    },
  },
  subTitle: {
    marginTop: '0.5rem',
    fontSize: '1.125rem',
    lineHeight: '1.687rem',
    color: '#5C5C5C'
  },
  orderby: {
    width: '18.25rem',
    marginTop: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '1rem',
    },
  },
  creditLines: {
    marginTop: '1rem',
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
    justifyItems: 'center',
    maxHeight: '35rem',
    overflow: 'auto',
    '&::-webkit-scrollbar-thumb': {
      border: '4px solid white',
      borderRadius: '50px',
      backgroundColor: 'rgba(0, 0, 0, .3)',
    },
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: '28rem',
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'transparent',
      },
      '&::-webkit-scrollbar': {
        width:'1px'
      },
    },
  },
  buttonConfirmLine: {
    width: '192px',
    marginTop: '40px',
    marginBottom: '168px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '64px',
    },
  },
  heightSelect:{
    height: '48px',
  },
}));

const BudgetCreditLines = ({
  filterOptions = {},
  routeMode,
}) => {
  const classes = useStyles();
  const { budget, budgetDispatch } = useAppContext();
  const { car, creditLine, orderCreditsLines } = budget;
  const [filter, setFilter] = useState(orderCreditsLines);
  const [selectedCreditLineId, setSelectedCreditLineId] = useState(creditLine?.id ? creditLine.id : -1);
  const handleSortBy = (e) => {
    setFilter(e.target.value);
    setSelectedCreditLineId(-1);
  };

  const selectCreditLine = (id) => () => {
    setSelectedCreditLineId(id);
  };

  const handleConfirm = () => {
    // Se obtiene la linea seleccionada
    let selectedLine = creditLinesResponse.getCreditLines.find(creditLine => creditLine.id === selectedCreditLineId);
    const selectedCondition = selectedLine.lines[0];
    setCreditLine(selectedLine, selectedCondition, budgetDispatch);

    goToFinancingLineDetails(routeMode);
  };

  const { loading: loadingCreditLines, data: creditLinesResponse } = useQuery(GET_CREDIT_LINES, {
    variables: {
      carLotId: car.carLot?.id,
      brandId: car.brand?.id?.[0],
      modelId: car.model?.id?.[0],
      vehicleType: car.is0km ? BUDGET.NEW_CAR_TYPE : BUDGET.USED_CAR_TYPE,
      catalogId: car.id,
      manufactureYear: car.is0km ? null : car.year,
      sortBy: filter
    },
    // Se agrega la politica network only para que siempre
    // pase por el ciclo de vida completo y actualice la vista
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setOrderCreditLine(filter, budgetDispatch);
    },
  });

  const handleBackClick = useCallback(() => {
    if(routeMode === BUDGET.CREDIT_LINE_CREATE_MODE) {
      deleteCreditLine(budgetDispatch);
    }

    goToPresupuesto(car);
  }, [car,budgetDispatch, routeMode]);

  useEffect(() => {
    budgetDispatch({
      type: SET_BACK_BUTTON,
      data: {
        onBackClick: handleBackClick,
        backLabel: 'Volver al presupuesto'
      },
    });
  }, [car, budgetDispatch, handleBackClick, routeMode]);

  useEffect(() => {
    if (creditLinesResponse?.getCreditLines?.length > 0) {
      if(creditLine.id) {
        setSelectedCreditLineId(creditLine.id);
      } else {
        setSelectedCreditLineId(creditLinesResponse.getCreditLines[0].id);
      }
    }
  }, [creditLinesResponse?.getCreditLines, filter]);


  if (loadingCreditLines) {
    return <LoadingModal />;
  }

  return (
    <Box display='flex' flexDirection='row' justifyContent='center'
      className={classes.container} id="detalle-container"
    >
      <Box className={classes.root}>
        <Box display='flex' flexDirection='column' className={classes.header}>
          <Text bold>{TITLE}</Text>
          <Text normal className={classes.subTitle}>{SUB_TITLE}</Text>
        </Box>
        <Box className={classes.orderby}>
          <SelectV2
            id='select-filter'
            label={LABEL_ORDEN}
            onChange={handleSortBy}
            value={filter}
            isFromCatalog={true}
            options={filterOptions.map((option) => ({
              label: option.label,
              value: option.value
            }))}
            className={classes.heightSelect}
          />
        </Box>
        {creditLinesResponse?.getCreditLines?.length
          ? <Box className={classes.creditLines}>
            {creditLinesResponse.getCreditLines
              .map((creditLine, i) => <CardCreditLine
                key={i}
                creditLine={creditLine}
                condition={creditLine.lines[0]}
                showRadioButton
                isItemSelected={selectedCreditLineId === creditLine.id}
                onItemClick={selectCreditLine(creditLine.id)}
              />)
            }
          </Box>
          : null}
        {<Box id="-button-confirm-line" display='flex' justifyContent='flex-end'>
          <Button
            primary
            className={classes.buttonConfirmLine}
            type="submit"
            data-test-id="search-button"
            onClick={handleConfirm}
          >
            Confirmar línea
          </Button>
        </Box>}
      </Box>
    </Box>
  );
};

BudgetCreditLines.propTypes = {

};

export default BudgetCreditLines;
