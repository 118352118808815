import React,{ useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { PALETTE } from '../../assets/theme';
import { useMediaQuery } from '@material-ui/core';

const LOADING_TEXT = 'Cargando...';
const NO_OPTIONS_TEXT = 'No hemos encontrado coincidencias referentes a';
const useStyles = makeStyles(theme => ({
  searchBox: {
    '& legend > span': {
      display: 'none',
    },
  },
  label: {
    zIndex: 30,
  },
  labelError:{
    color:`${PALETTE.grayScale.g400}!important`
  },
  loading: {
    color: theme.palette.grayScale.g400,
  },
  labelFocused: {
    color: `${theme.palette.secondary.main}`,
  },
  popper: {
    '& .MuiAutocomplete-paper': {
      '@media (max-width: 796px) and (min-width: 480px)': {
        marginRight: 1,
      },
      '@supports ( -moz-appearance:none )': {
        marginRight: 1
      },
      borderRadius: theme.borderRadius.large,
      boxShadow: 'none',
      border: `1px solid ${theme.palette.grayScale.g200}`,
    },
  },
  menuItemText: {
    display: 'block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: theme.palette.grayScale.g400,
    fontSize: theme.typography.size.smaller,
    [theme.breakpoints.down('sm')] :{
      margin:0,
    }
  },
  inputRoot: {
    background: theme.palette.grayScale.g0,
    padding:'0 0 0 8px!important',
    zIndex: 20,
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    //Icon
    '&.Mui-error':{
      '& span': {
        '& svg': {
          color: PALETTE.error.main,
        }
      },
    },
    '& span': {
      '& svg': {
        color: PALETTE.primary.main,
      }
    },
    '& .Mui-disabled': {
      '& span': {
        '& svg': {
          color: PALETTE.grayScale.g300,
        }
      },
    }
  },
  searchButton: {
    border: 'none',
    background: 'none',
    marginLeft: '3px',
    padding: 0,
    cursor: 'pointer'
  },
  input:{
    width:'100%!important',
    flex:1
  }
}));

export default function AutocompleteSelect({
  id,
  value = '',
  loading,
  onChange = () => {},
  label = '',
  className,
  hideSearchIcon,
  handleSearch,
  disabled,
  filterOptions,
  options = [],
  type = 'text',
  noOptionsText,
  error,
  helperText,
  optional,
  classes: classesMui,
  classNameTextfield,
  disableClearable = true,
  ...autocompleteProps
}) {
  /**
   * Es necesario utilizar la funcion getOptionLabel desde el padre
   * tal que getOptionLabel={(option) => option.label}
   * donde label es el texto a mostrar como opcion
   */
  const [currentSearch, setCurrentSearch] = useState(value || '');
  const [selectedOption, setSelectedOption] = useState(value || '');
  const [hasError, setHasError] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleAutocompleteChange = (evt, val) => {
    setSelectedOption(val);
    setCurrentSearch(val?.value?.name || '');
    onChange(val?.value || null);
  };

  const handleInputChange = (evt,val) =>{
    setCurrentSearch(String(val));
    if(String(val).length > 1){
      setHasError(false);
    }
  };

  const handleBlur = () =>{
    if((!currentSearch || !selectedOption) && !optional) {
      setHasError(true);
    }
    if(!value){
      setCurrentSearch('');
    }
  };

  useEffect(()=>{
    if(!value && !selectedOption) {
      setCurrentSearch('');
    } else if(currentSearch) {
      const optionToSelect = options.filter(
        (option) => option.value === value || option.key === value || option.label === value
      );
      if(value && optionToSelect[0]){
        setCurrentSearch(optionToSelect[0].label);
      }else{
        setCurrentSearch('');
        setSelectedOption(null);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[value]);

  useEffect(()=>{
    if(!disabled && error &&
      ((!currentSearch || ( currentSearch && String(currentSearch).length < 2)) )
    ) {
      setHasError(true);
    }else if((currentSearch && String(currentSearch).length > 1) || disabled){
      setHasError(false);
    }
  },[error,disabled,currentSearch,selectedOption]);

  return (
    <MuiAutocomplete
      disablePortal={false}
      id={id}
      loading={loading}
      className={clsx(classes.searchBox, className)}
      classes={{
        popper: classes.popper,
        inputRoot: classes.inputRoot,
        loading: classes.loading,
        option: classes.menuItemText,
        ...classesMui
      }}
      fullWidth
      disableClearable={disableClearable}
      disabled={disabled}
      ListboxProps={{ style: { maxHeight: isMobile ? '30vh' : 300 }, position: 'bottom' }}
      loadingText={LOADING_TEXT}
      noOptionsText={`${NO_OPTIONS_TEXT} '${currentSearch}'`}
      options={options}
      onBlur={handleBlur}
      isOptionEqualToValue={(option, value) => option.key === value || option.value === value || option.label === value}
      onChange={handleAutocompleteChange}
      onInputChange={handleInputChange}
      value={selectedOption}
      inputValue={currentSearch}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={hasError}
          variant="outlined"
          type={type}
          InputLabelProps={{
            classes: {
              root: classes.label,
              focused: classes.labelFocused,
              error: classes.labelError
            },
            ...params.InputLabelProps,
          }}
          InputProps={{
            ...params.InputProps,
            classes:{
              input: classes.input
            },
          }}
          className={classNameTextfield}
          helperText={hasError && helperText}
        />
      )}
      {...autocompleteProps}
    />
  );
}
