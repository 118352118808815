import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Text from '../common/text.component';
import { formatFractionDigits } from '../../utils/currency.utils';

const currencyFormat = (num) => {
  return '$ ' + Number(num).toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
};

const useStyles = makeStyles (theme => ({
  sectionInfoFinancingLineBank:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.5rem',
    },
  },
  txtMonth:{
    color: '#5C5C5C',
    fontWeight: 600,
    marginRight: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  txtbank:{
    color: '#5C5C5C',
    fontWeight: 400,
    textTransform: 'upperCase'
  },
  txtYearKmState:{
    color: '#717171',
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: 'initial',
    },
  },
  divider:{
    width: '4px',
    height: '4px',
    background: '#717171',
    borderRadius: '50%',
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
  },
  priceFinancingLine:{
    color: '#262626',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  txtNameComercial:{
    height: '17px',
    fontSize: '13px',
    color: '#717171',
  },
  txtFinanciacion:{
    color: '#5C5C5C',
    fontWeight: 600,
    marginRight: '0.5rem',
    marginTop: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  priceFinancingExpenses:{
    color: '#5C5C5C',
    fontWeight: 500,
    alignSelf: 'end',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  txtBank:{
    fontSize: '16px',
  },
  txtBankName:{
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  txtnameComercial:{
    fontSize: '13px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  boxMonthBank:{
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      width:'13rem',
    },
  }
}));

const FinancingLine = ({
  financingLine,
  priceFinancingExpenses
}) => {
  const classes = useStyles();

  const financedAmountFormat = currencyFormat(financingLine.financedAmount)
  const priceFinancingExpensesFormat = currencyFormat(priceFinancingExpenses)

  return(
    <Box className={classes.sectionInfoFinancingLine}>
      <div className={classes.sectionInfoFinancingLineBank}>
        <div className={classes.boxMonthBank}>
          <Text className={classes.txtMonth}>{financingLine.condition.quantity} meses</Text>
          <Text className={classes.txtbank}>TNA {formatFractionDigits(financingLine.condition.tna)}% / CFT {formatFractionDigits(financingLine.condition.cft)} %</Text>
        </div>
        <div>
          <Text className={classes.priceFinancingLine}>-{financedAmountFormat}</Text>
        </div>
      </div>
      <div className={classes.txtYearKmState}>
        <Text className={classes.txtBankName}>{financingLine.bank}</Text>
        <div className={classes.divider}></div>
        <Text className={classes.txtnameComercial}>{financingLine.nameComercial}</Text>
      </div>
      <div className={classes.sectionInfoFinancingLineBank}>
        <div className={classes.txtFinanciacion}>
          <Text>Gastos de financiación</Text>
        </div>
        <Text className={classes.priceFinancingExpenses}>{priceFinancingExpensesFormat}</Text>

      </div>
    </Box>
  );
};


export default FinancingLine;
